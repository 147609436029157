import getQueryVariable from "./getQueryVar";

const sendDataToGA = () => {

    console.log('partito GA');
  
    const current_store = getQueryVariable('storeID');
    const current_color = getQueryVariable('colorCode');
    const current_size  = getQueryVariable('sizeCode');
    const qr_code       = getQueryVariable('QR');

    if (typeof window !== "undefined") {
        console.log('partito GA - linea 13');
        if (window.ga) {
            console.log('partito GA - linea 15');
            
            if (current_color && current_store && current_size) {
                console.log('partito GA - linea 18');
                
                const ga = window.ga;
                ga('set', 'dimension1', current_store);
            
                if (window.location.pathname == "/") {
                    ga('send', 'pageview', {'sessionControl': 'start'});
                }

                if(current_color && current_size  && qr_code) {
                    
                    console.log('partito GA - event category QR CODE');
                    
                    ga('set', 'dimension2', current_color);
                    ga('set', 'dimension3', current_size);		  
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'QRcode',
                        eventAction: current_color,
                        eventLabel: current_size,
                        hitCallback: function () {
                            console.log("Google event category event sent");
                          }
                    });	  
                }
                
                if(current_color && current_size && !qr_code) {

                    console.log('partito GA - event category APP');

                    ga('set', 'dimension2', current_color);
                    ga('set', 'dimension3', current_size);		  
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'App',
                        eventAction: current_color,
                        eventLabel: current_size
                    });	  
                }
                console.log('partito GA - linea 55');
            }
        }
      }

    return false;

}

export default sendDataToGA;