function getQueryVariable(variable) {
    if (typeof window !== "undefined") {
        var query = window.location.search.substring(1);
        var vars = query.split('&');

        console.log('vars', vars);
        console.log('vars query', query);

        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
      }
}
export default getQueryVariable;