import React from 'react';
import getQueryVariable from '../../../utils/getQueryVar';

const Content = ({content}) => {
    const colorCode = getQueryVariable('colorCode');
    const sizeCode = getQueryVariable('sizeCode');
    const fasciaCode = getQueryVariable('fasciaCode');

    const colors = content?.acf?.chooose_your_tv?.first_panel?.color_blocks;
    const sizes = content?.acf?.chooose_your_tv?.second_panel?.sizes?.sizes;

    const actualColor = colors.filter((itm) => itm.color_code === colorCode);    
    const findInd = colors.findIndex((itm) => itm.color_code === colorCode);    
    const size_code = sizes.filter((itm) => itm.size_code === sizeCode); 
    const size = size_code[0].size;

    
    const image = content?.acf?.chooose_your_tv?.media?.main_images_with_size[findInd].color_cluster.filter((el) => el.sizecode === sizeCode);
    const fasciaList = content?.acf?.chooose_your_tv?.fascia_panel?.color_blocks.filter((el) => el.color_code === fasciaCode);
    const actualFascia = fasciaList[0].list_of_images_for_colours[size.toLowerCase()].url;
    const actualFasciaColor = fasciaList[0];
    


    //console.log('actualFascia', actualFasciaColor);
    

    return (
        <div className="mobileProduct">
        <header>
            <div className="newHeader">
                <img src={content?.acf?.main_info?.logo} />
            </div>
        </header>
        <footer>
            <div className="cta">
                <p dangerouslySetInnerHTML={{__html: content?.acf?.product_page?.cta}}></p>
            </div>
        </footer>
        <main>
            <div className="text">
                <div dangerouslySetInnerHTML={{__html: content?.acf?.product_page?.main_text}}></div>
            </div>
            <div className="img" style={{padding:30}}>
                <div className="imgCont" style={{position:'relative'}}>
                    {image.length > 0 && <img src={image[0].image.sizes.large} />}
                    {actualFascia && <img  style={
                        {
                            position:'absolute',
                            top:0,
                            left:0,
                            width:'100%',
                            height:'auto',
                        }
                    } src={actualFascia} />}
                </div>
            </div>
            <div className="bar">
                <div className="actualBarra"></div>
                <p style={{color:'#4D4D4D', textAlign:'left', padding: '15px 15px 0px 15px'}}>Featured In:</p>
                <ul>
                    <li>
                        <p style={{color:'#4D4D4D'}}>Size:</p>
                        {size_code.length > 0 && (
                            <span className="data2Response dataResponse">
                                <div style={{padding:'2px 5px', fontSize: '16px', borderRadius:4, display:'inline-block', marginRight:5,marginTop:-5, border:'1px solid #091153'}}>
                                    {size_code[0]?.size}
                                </div>
                                {size_code[0]?.inches}''
                            </span>
                        )}
                    </li>
                    <li>
                        <p style={{color:'#4D4D4D'}}>Colour:</p>
                        {actualColor.length > 0 && (
                            <span className="data1Response dataResponse">
                                <div style={{width:15,height:15,background:actualColor[0].hex_color, borderRadius:15, display:'inline-block', marginRight:5, boxShadow:'0px 0px 1px 1px rgba(0,0,0,0.33)'}}>
                                </div>
                                {actualColor[0]?.color_title}
                            </span>
                        )}
                    </li>
                    <li>
                        <p style={{color:'#4D4D4D'}}>Speakers:</p>
                        {actualFasciaColor && (
                            <span className="data1Response dataResponse">
                                <div style={{width:15,height:15,background:actualFasciaColor.hex_color, borderRadius:15, display:'inline-block', marginRight:5, boxShadow:'0px 0px 1px 1px rgba(0,0,0,0.33)'}}>
                                </div>
                                {actualFasciaColor?.color_title}
                        </span>
                        )}
                    </li>
                </ul>
            </div>
        </main>
        </div>
    )
}

export default Content;