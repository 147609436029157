import React, {useEffect} from 'react';
import MobilePage from '../../components/product/MobilePage';
import sendDataToGA from '../../../utils/ga';


const Index = () => {

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (window.ga) {
                setTimeout(() => {
                    sendDataToGA();
                }, 1000);
            }
          }
      }, [])

    return <MobilePage/>
}

export default Index;