import React, {useState, useEffect} from 'react';
import spinner from '../../images/spinner.svg';
import Content from './Content';
import {GATSBY_BE_URL} from '../../../config/var';


const MobilePage = () => {
    const [load, setLoad] = useState(1)
    const [content, setContent] = useState(null);
    const [error, setError] = useState(null);



    useEffect(() => {
        try {
            // const productID = getQueryVariable('productID');
            // TODO done - use 4 testing
            // fetch(`https://skyglass-staging.stentle.com/wp-json/wp/v2/pages/2`)
            fetch(`${process.env.GATSBY_BE_URL}/wp-json/wp/v2/pages/2`)
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    setError('wrong page');
                }
            })
            .then((data) => {
                if (data) {
                    setContent(data);
                }
            })
            setLoad(0);
        } catch (error) {
            setLoad(0);
            setError('500');
        }

    }, [])

    let contenuto = (
        <div className="spinner">
            <img src={spinner} />
        </div>
    )

    contenuto = content && <Content content={content}/>

    return contenuto;
}


export default MobilePage;